<header>
  <div class="navbar navbar-fixed">
    <nav class="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-indigo-light-blue gradient-shadow">
      <div class="nav-wrapper">
        <div class="header-text {{ horizontal ? 'header-text-full' : '' }}">Công Ty Đấu Giá Hợp Danh VNA</div>
        <ul class="navbar-list right">
          <li>
            <a class="waves-effect waves-block waves-light" (click)="horizontalMenu()" title="{{ horizontal ? 'Menu dọc' : 'Menu ngang' }}">
              <i class="pi {{ horizontal ? 'pi-window-minimize' : 'pi-window-maximize' }}"></i>
            </a>
          </li>
          <li>
            <a class="waves-effect waves-block waves-light" (click)="logout()" title="Đăng xuất">
              <i class="pi pi-sign-out"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
