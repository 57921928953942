import {Component, Input, OnInit} from '@angular/core';

import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html'
})
export class AsideComponent implements OnInit {

  @Input() items!: MenuItem[];

  constructor() {
  }

  ngOnInit(): void {
  }

  collapse(event: any): void {
    event.target.parentElement.classList.toggle('active');
  }

}
