import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {LoginService} from '../../../../pages/login/login.service';
import {EntitiesService} from '../../../../entities/entities.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  horizontal!: boolean;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private entitiesService: EntitiesService
  ) {
  }

  ngOnInit(): void {
    this.entitiesService.getHorizontal().subscribe((horizontal) => {
      this.horizontal = horizontal;
    });
  }

  horizontalMenu(): void {
    this.entitiesService.setHorizontal(!this.horizontal);
  }

  logout(): void {
    this.loginService.logout().subscribe(() => {
      this.router.navigateByUrl('/login').then();
    });
  }

}
