import {Injectable} from '@angular/core';

import languages from './languages';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  locale: string;

  constructor() {
    // this.locale = localStorage.getItem('son_locale');
    // if (!this.locale) {
    //   this.setLocale('vi');
    // }
    this.locale = 'vi';
  }

  setLocale(locale: string): void {
    if (locale) {
      this.locale = locale;
      localStorage.setItem('son_locale', locale);
      location.reload();
    }
  }

  get(value: string): string {
    if (value && value.includes('+')) {
      let result = '';
      value.split('+').forEach((text) => {
        result += ` ${this.get(text)}`;
      });
      return result.trim();
    } else {
      if (languages[value] && languages[value][this.locale] !== undefined) {
        return languages[value][this.locale];
      }
    }
    return value;
  }
}

// @ts-ignore
import * as convert from './num2Word2';

export class AmountInWord {

  static convert(amount: any): string {
    let text = convert(amount);
    if (text && text.length > 1) {
      text = text.charAt(0).toUpperCase() + text.slice(1);
    }
    return text;
  }

}
