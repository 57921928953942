import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';

import {UserService} from '../shared/service/user.service';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  private readonly horizontal: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient
  ) {
    const horizontal = UserService.get('horizontal');
    this.horizontal = new BehaviorSubject<boolean>(horizontal === 'Y');
  }

  getHorizontal(): BehaviorSubject<boolean> {
    return this.horizontal;
  }

  setHorizontal(horizontal: boolean): void {
    UserService.set('horizontal', horizontal ? 'Y' : 'N')
    this.horizontal.next(horizontal);
  }

  getMenus(): Observable<any> {
    return this.http.get(`menus`);
  }

}
