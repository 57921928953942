import {Component, Input, OnInit} from '@angular/core';

import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  @Input() items!: MenuItem[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
