import {Component, OnInit} from '@angular/core';

import {SocketService} from './shared/service/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
    SocketService.init();
  }

}
