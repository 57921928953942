import {Component, OnInit} from '@angular/core';

import {ConfirmationService} from 'primeng/api';

import {ConfirmService} from './confirm.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {

  constructor(
    private confirmationService: ConfirmationService,
    private confirmService: ConfirmService
  ) {
  }

  ngOnInit(): void {
    this.confirmService.getEvent().subscribe((event) => {
      if (event && event.target) {
        this.confirmationService.close();
        setTimeout(() => {
          this.confirmationService.confirm({
            target: event.target,
            message: event.message,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Có',
            rejectLabel: 'Không',
            accept: event.accept
          });
        }, 200);
      }
    });
  }

}
