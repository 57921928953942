import {Component, OnInit} from '@angular/core';

import {MenuItem} from 'primeng/api';

import {EntitiesService} from './entities.service';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html'
})
export class EntitiesComponent implements OnInit {

  horizontal: boolean;

  menus: MenuItem[];

  constructor(
    private entitiesService: EntitiesService
  ) {
  }

  ngOnInit(): void {
    this.entitiesService.getHorizontal().subscribe((horizontal) => {
      this.horizontal = horizontal;
    });

    this.entitiesService.getMenus().subscribe(response => {
      this.menus = response;
    });
  }

}
