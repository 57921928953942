import {Injectable} from '@angular/core';

@Injectable({
  providedIn: `root`
})
export class UserService {

  static readonly app: string = 'iam-dhcd';

  static setUser(user: any): void {
    localStorage.setItem(`${this.app}_user`, JSON.stringify(user));
  }

  static getUser(): any {
    return JSON.parse(localStorage.getItem(`${this.app}_user`) || '{}') as any;
  }

  static setRouterState(routerState: string): void {
    localStorage.setItem(`${this.app}_routerState_${this.getUser()._id}`, routerState);
  }

  static getRouterState(): any {
    return localStorage.getItem(`${this.app}_routerState_${this.getUser()._id}`);
  }

  static set(key: string, value: string): void {
    localStorage.setItem(`${this.app}_${key}_${this.getUser()._id}`, value);
  }

  static get(key: string): any {
    return localStorage.getItem(`${this.app}_${key}_${this.getUser()._id}`);
  }

}
