import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RouteGuard} from './shared/guard/route.guard';

import {EntitiesComponent} from './entities/entities.component';

const routes: Routes = [
  {
    path: '',
    component: EntitiesComponent,
    children: [
      {path: '', redirectTo: 'Home', pathMatch: 'full'},
      {
        path: 'Home',
        loadChildren: () => import('./entities/home/home.module').then(mod => mod.HomeModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'accounts',
        loadChildren: () => import('./entities/accounts/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'user-groups',
        loadChildren: () => import('./entities/user-groups/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'customers',
        loadChildren: () => import('./entities/customers/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'clients',
        loadChildren: () => import('./entities/clients/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'categories',
        loadChildren: () => import('./entities/categories/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'products',
        loadChildren: () => import('./entities/products/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'posts',
        loadChildren: () => import('./entities/posts/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'home-decorate',
        loadChildren: () => import('./entities/home-decorate/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'communications',
        loadChildren: () => import('./entities/communications/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'system-logs',
        loadChildren: () => import('./entities/system-logs/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'api-data',
        loadChildren: () => import('./entities/api-data/items.module').then(mod => mod.ItemsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      }
    ]
  },
  {path: 'login', loadChildren: () => import('./pages/login/login.module').then(mod => mod.LoginModule)},
  {path: '**', redirectTo: 'Home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
