import {Injectable} from '@angular/core';

import io from 'socket.io-client';

@Injectable({
  providedIn: `root`
})
export class SocketService {

  static socket: any;

  static init(): void {
    this.socket = io({
      path: '/ocean/socket.io'
    });
  }

  static joinRoom(room: string): void {
    this.socket.emit('join-room', room);
  }

  static leaveRoom(room: string): void {
    this.socket.emit('leave-room', room);
  }

}
