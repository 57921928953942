<aside class="sidenav-main nav-collapsible sidenav-light navbar-full sidenav-active-rounded nav-lock">
  <div class="brand-sidebar">
    <h1 class="logo-wrapper">
      <a class="brand-logo darken-1">
        <span class="logo-text">VNA Partnerships Auction</span>
      </a>
    </h1>
  </div>
  <ul class="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="!item.items">
        <li class="bold">
          <a class="waves-effect waves-cyan" [routerLink]="item.routerLink" routerLinkActive="active gradient-45deg-indigo-light-blue">
            <i class="{{ item.icon }}"></i><span class="menu-title">{{ item.label }}</span>
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="item.items">
        <li class="bold open">
          <a class="collapsible-header waves-effect waves-cyan" (click)="collapse($event)">
            <i class="{{ item.icon }}"></i><span class="menu-title">{{ item.label }}</span>
          </a>
          <div class="collapsible-body">
            <ul>
              <li *ngFor="let child of item.items">
                <a [routerLink]="child.routerLink" routerLinkActive="active gradient-45deg-indigo-light-blue">
                  <i class="far fa-circle"></i><span>{{ child.label }}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</aside>
